import { template as template_56731ca5ead44dad852df06e6147db27 } from "@ember/template-compiler";
const FKControlMenuContainer = template_56731ca5ead44dad852df06e6147db27(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
