import { template as template_94f79f57015f4990b4c6d4f4c941c067 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
export default class DeferredRender extends Component {
    @tracked
    shouldRender = false;
    constructor(){
        super(...arguments);
        runAfterFramePaint(()=>(this.shouldRender = true));
    }
    static{
        template_94f79f57015f4990b4c6d4f4c941c067(`
    {{#if this.shouldRender}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
