import { template as template_2f8a762e1c9a4b51856c925a2290c81e } from "@ember/template-compiler";
const FKLabel = template_2f8a762e1c9a4b51856c925a2290c81e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
